<template>
  <div class="allmsgclass" v-wechat-title="$route.meta.title">
    <navigation msg="充值"></navigation>
    <div class="cjzsclass">
      <img src="../../assets/czjs.png" alt="" />
      <img
        @click.stop="$refs.kefuid.showmeth()"
        class="twoimg"
        src="../../assets/czjs2.png"
        alt=""
      />
    </div>
    <div class="jineclass">选择充值金额</div>
    <div class="semoney">
      <div
        @click="clickindex(0)"
        :style="seindex == 0 ? 'border: 0.02rem solid #EC5D33;' : ''"
      >
        ¥200 <img v-show="seindex == 0" src="../../assets/semoney.png" alt="" />
      </div>
      <div
        @click="clickindex(1)"
        :style="seindex == 1 ? 'border: 0.02rem solid #EC5D33;' : ''"
      >
        ¥500 <img v-show="seindex == 1" src="../../assets/semoney.png" alt="" />
      </div>
    </div>
    <div class="jineclass">自定义充值</div>

    <div class="allpageclass11">
      <div class="themoneyclass">
        <div class="inmoneyclass">
          <div>¥</div>
          <div>
            <input
              v-model="money"
              type="number"
              onkeyup="this.value=this.value.replace(/[^0-9-]+/,'');"
              placeholder="请输入充值金额"
            />
          </div>
        </div>
      </div>
      <div class="jineclass">选择支付方式</div>

      <div class="mymenu">
        <div @click="pay('wechart')">
          <div class="aaa">
            <img src="../../assets/wxpay.png" />
            <p class="wxpayclass">微信支付</p>
          </div>
          <div class="seclasspay">
            <van-icon size=".25rem" name="success" />
          </div>
        </div>
      </div>
      <button @click="paynow('充值成功')" class="bton paybt">
        确认支付{{ money ? money + "元" : "" }}
      </button>
    </div>
    <kefu ref="kefuid"></kefu>
  </div>
</template>
<script>
import base from "../../../util/base";
import kefu from "../../components/kefu/index.vue";
export default {
  data() {
    return {
      alipay: true,
      wechart: true,
      fast: false,
      selected: require("../../assets/selected.png"),
      unselected: require("../../assets/unselected.png"),
      color: "#FE5600",
      user: [],
      show: false,
      money: "",
      noborder: "noborder",
      seindex: 0,
    };
  },
  components: { kefu },
  mounted() {
    this.selectmoney();
    this.clickindex(0);
  },
  watch: {
    money: function(newmsg, oldmsg) {
      if (newmsg < 0) {
        this.money = this.money.replaceAll("-", "");
      }
      var reg = /^(\d{0,8})(\.(\d{0,2}))?$/g;
      if (!reg.test(newmsg)) {
        if (newmsg == "") {
          this.money = "";
          return;
        }
        this.money = oldmsg;
      } else {
        this.money = newmsg;
      }
      if (newmsg == 200) {
        this.seindex = 0;
      } else if (newmsg == 500) {
        this.seindex = 1;
      } else {
        this.seindex = -1;
      }
    },
  },
  methods: {
    clickindex(index) {
      this.seindex = index;
      if (index == 0) {
        this.money = 200;
      } else if (index == 1) {
        this.money = 500;
      }
    },
    selectmoney() {
      let than = this;
      this.$http
        .post("/firm/v1/index/index", {
          reqType: "resume",
        })
        .then((res) => {
          res = JSON.parse(res.data);
          if (res.code == 0) {
            than.user = res.data;
          }
        });
    },
    pay(msg) {
      if (msg == "alipay") {
        this.alipay = true;
        this.wechart = false;
        this.fast = false;
      } else if (msg == "wechart") {
        this.alipay = false;
        this.wechart = true;
        this.fast = false;
      } else if (msg == "fast") {
        this.alipay = false;
        this.wechart = false;
        this.fast = true;
      }
    },
    onSubmit(values) {
      this.show = false;

      this.$paymeth({
        money: values.money,
        orderType: 1,
      });
    },
    paynow(msg) {
      // this.show = true;
      const isMiniProgram = /miniProgram/i.test(
        navigator.userAgent.toLowerCase()
      );
      if (isMiniProgram) {
        this.$toast("如需充值请到pc端进行充值");
        return;
      }
      if (this.money < 200) {
        this.$toast("最低充值200元");
        return;
      }
      let a = 10;
      let sett = setInterval(() => {
        a--;
        if (a < 1) {
          clearInterval(sett);
        }
        this.selectmoney();
      }, 2000);
      //调起支付 money：金额
      this.$paymeth({
        money: this.money,
        orderType: 1, //订单类型 1.充值、2.购买。3、购买套餐、4.活动购买、5.购买套餐包、8：购买套餐
        url: base.url + "/user", //回调地址 支付成功之后跳转到哪个页面
      });
    },
  },
};
</script>
<style scoped>
.cjzsclass .twoimg {
  position: absolute;
  right: 0.4rem;
  bottom: 0.15rem;
  width: 2rem;
  left: 50%;
  transform: translateX(-50%);
}
.seclasspay {
  background-color: #1890ff;
  color: #ffffff;
  text-align: center;
  padding: 0.08rem 0.08rem;
  border-radius: 0.04rem;
}
.wxpayclass {
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #303133;
  font-weight: 500;
}
.semoney {
  display: flex;
  align-items: center;
  margin: 0 0.24rem;
  margin-bottom: 0.5rem;
}
.semoney img {
  position: absolute;
  width: 0.7rem;
  right: 0;
  top: 0;
}
.semoney > div {
  width: 50%;
  text-align: center;
  background: #ffffff;
  border-radius: 0.16rem;
  border: 0.02rem solid #efefef;
  margin-right: 0.28rem;
  padding: 0.44rem 0;
  font-size: 0.42rem;
  line-height: 0.48rem;
  color: #303133;
  position: relative;
}
.semoney > :last-child {
  margin-right: 0;
}
.cjzsclass {
  margin: 0.32rem 0.34rem;
  position: relative;
}
.cjzsclass img {
  width: 100%;
}
.jineclass {
  margin: 0.24rem 0.32rem;
  margin-top: 0.52rem;
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #303133;
  font-weight: 500;
}

.msgcll {
  margin-top: 0.33rem;
  background-color: #ffffff;
  padding: 0.3rem 0.46rem;
}
.imgpay {
  width: 100%;
}
.allmsgclass {
  min-height: 100%;
  background-color: white;
}
.moneynum span:first-child {
  font-size: 0.4rem;
  font-weight: 500;
}
.moneynum {
  margin-top: 0.15rem;
  line-height: 0.9rem;
  font-size: 0.7rem;
}
.yueclass {
  line-height: 0.45rem;
  font-size: 0.32rem;
}
.mymenu div .aaa {
  margin: 0;
}
.mty {
  margin: 0.45rem 0.31rem;
  font-size: 0.32rem;
  line-height: 0.45rem;
  font-weight: bold;
}
.inmoneyclass input {
  border: none;
  font-size: 0.28rem;
  line-height: 0.48rem;
  width: 100%;
  color: #909399;
  font-weight: 400;
}
.inmoneyclass > div:first-child {
  font-size: 0.56rem;
  line-height: 0.48rem;
  font-weight: 500;
}
.inmoneyclass > div:nth-child(2) {
  font-size: 0.34rem;
  line-height: 0.48rem;
  font-weight: bold;
  margin-left: 0.18rem;
  width: 100%;
}
.inmoneyclass {
  display: flex;
  align-items: center;
}
.themoneyclass > div:first-child {
  font-weight: bold;
}
.themoneyclass {
  padding: 0.31rem 0;
  padding-top: 0.12rem;
  margin: 0 0.31rem;
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #141f33;
  border-bottom-color: #e7e7e7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.noclassmsg {
  background-color: #f5f7fa;
  height: 0.2rem;
}
.allpageclass11 {
  background-color: #ffffff;
  padding-bottom: 1rem;
}
[class*="van-hairline"]::after {
  border: none;
}
.van-field__label {
  width: 4rem;
}
.van-field__control {
  max-width: 2rem;
}
.balance {
  background: linear-gradient(#fe5600, #fc9e46);
  color: #ffffff;
  text-align: center;
  height: 3.25;
  padding: 0.82rem 0;
  margin-top: -2px;
}
.balance div:first-child {
  display: flex;
  margin: auto;
}
.balance div:first-child img {
  width: 0.4rem;
  height: 0.42rem;
  margin-right: 0.26rem;
}
.balance div:first-child div {
  font-size: 0.32rem;
  font-weight: normal;
}
.balance div:nth-child(2) {
  font-size: 0.7rem;
  font-weight: bold;
}
.yu {
  margin: auto;
}

.mymenu div img {
  width: 0.5rem;
}
.mymenu div div img {
  width: 0.56rem;
  margin-right: 0.2rem;
}
.mymenu {
  padding-left: 0;
  padding-right: 0;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0.08rem;
}
.mymenu > div {
  margin: 0;
}
.paybt {
  margin-top: 1.44rem;
}
input::placeholder {
  color: #c1c5ce;
  font-weight: 500;
}
</style>
